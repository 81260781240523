import PropTypes from 'prop-types'
import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import useTranslation from 'next-translate/useTranslation'
import { withRouter } from 'next/router'
import Link from 'next/link'
import { GroupsList, SubGroupsList } from 'SRC/modules/groups/components'
import { CategoriesFilter } from 'SRC/modules/ads/filter/components'
import { FilterButtons } from 'SRC/modules/layout/Header/components/FilterButtons'
import { getCurrentGroup } from 'SRC/modules/groups/selectors'
import { getCurrentSubCategory } from 'SRC/modules/categories/selectors'
import { SecureShoppingBox } from 'SRC/modules/common/components/SecureShoppingBox'
import config from 'SRC/config/config.json'
import translations from '../../../../../public/data/translations.json'

const getFilterBtnHref = router => {
  return {
    pathname: '/mobileAdFilter',
    query: router.query
  }
}

const getFilterBtnAs = (router, locale) => {
  return `/${translations.pretragaOglasa[locale]}${router.asPath}`
}

const GroupsMenuMobile = ({
  groups,
  currentGroup,
  currentSubcategory,
  router
}) => {
  const { t, lang } = useTranslation('common')

  const locale = lang?.toUpperCase() || 'ME'

  return (
    <React.Fragment>
      <FilterButtons />
      
      <div className='kategorije'>
        <GroupsList />

        <SubGroupsList className='kategorije-sub' />

        <div className='kategorije-pretraga'>
          {currentGroup && config.search.showTextSearchAndCategoryMenuOn.includes(currentGroup.seo) ? (
            <CategoriesFilter
              baseAs={`/${translations.pretragaOglasa[locale]}/`}
              baseHref={{ pathname: '/mobileAdFilter' }}
              baseHrefQuery={{ page: 'category-filter' }}
            />
          ) : null}
        </div>

        {router && router.asPath !== '/' && currentSubcategory ? (
          <Link href={getFilterBtnHref(router)} as={getFilterBtnAs(router, locale)}>
            <a className='filter-btn'>
              {t('Filtriraj za detalje')}
            </a>
          </Link>
        ) : null}

        {/* <SecureShoppingBox /> */}
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    currentGroup: getCurrentGroup(state),
    currentSubcategory: getCurrentSubCategory(state)
  }
}

GroupsMenuMobile.defaultProps = {
  groups: []
}

GroupsMenuMobile.propTypes = {
  currentGroup: PropTypes.shape({
    categories: PropTypes.array,
    seo: PropTypes.string
  }),
  isAdPage: PropTypes.bool.isRequired,
  currentSubcategory: PropTypes.object,
  router: PropTypes.shape({
    pathname: PropTypes.string
  }).isRequired
}


const enhance = compose(
  withRouter,
  connect(mapStateToProps)
)

export default enhance(GroupsMenuMobile)
