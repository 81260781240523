import React from 'react'
import useTranslation from 'next-translate/useTranslation'
import { withRouter } from 'next/router'
import PropTypes from 'prop-types'
import Link from 'next/link'
import ReactSVG from 'react-svg'
import translations from '../../../../../../public/data/translations.json'

export const FilterButtons = ({ router }) => {
	const { t, lang } = useTranslation('common')

	const locale = lang?.toUpperCase() || 'ME'

	const filterSearchHref = {
		pathname: '/mobileAdFilter',
		query: { page: 'category-filter', ...router.query }
	}

	const filterSearchAs = `${translations.pretragaOglasa[locale]}${router.asPath}`

	return (
		<ul className='filter-navigation'>
			<li className='filter-navigation-item'>
				<Link
					href={{ pathname: '/mobileAdFilter', query: { page: translations.tekstualnaPretraga[locale] } }}
					as={`/${translations.pretragaOglasa[locale]}/${translations.tekstualnaPretraga[locale]}`}
				>
					<a>
						<ReactSVG
							src={'/icons/pretraga-po-tekstu.svg'}
							beforeInjection={svg => {
								svg.setAttribute('style', 'width: auto; margin-top: 5px; height: 35px;')
							}} wrapper='span'
						/>
						<div className='filter-navigation-item-text'>
							<p>{t('Pretraga')}</p>
							<p>{t('po tekstu')}</p>
						</div>
					</a>
				</Link>
			</li>

			<li className='filter-navigation-item'>
				<Link
					href={filterSearchHref}
					as={filterSearchAs}
				>
					<a>
						<ReactSVG
							src={'/icons/pretraga-po-filterima.svg'}
							beforeInjection={svg => {
								svg.setAttribute('style', 'width: auto; margin-top: 5px; height: 35px;')
							}} wrapper='span'
						/>
						<div className='filter-navigation-item-text'>
							<p>{t('Pretraga')}</p>
							<p>{t('po filterima')}</p>
						</div>
					</a>
				</Link>
			</li>
		</ul>
	)
}

FilterButtons.propTypes = {
	router: PropTypes.shape({
		asPath: PropTypes.string
	}).isRequired
}

export default withRouter(FilterButtons)
